import React, {Suspense, lazy} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { useAuth } from './context/Auth';
import Loader from './components/Loader';
const Register = lazy(()=>import('./routes/Auth').then(module=>({ default: module.Register})))
const Login = lazy(()=>import('./routes/Auth'));
const Home = lazy(()=> import('./routes/Home'))
const Dashboard = lazy(()=>import('./routes/Dashboard'))
const  Platform = lazy(()=>import( './routes/Platform'));
const App = () => {
  const {authLoading} = useAuth();
  if (authLoading) {
    return <Loader/>
  } else {
    return (
      <Router>
        <Suspense fallback={<Loader/>}>
          <Switch>
            <Route path="/login">
              <Login/>
            </Route>
            <Route path="/register">
              <Register/>
            </Route>
            <Route path="/dashboard">
              <Dashboard></Dashboard>
            </Route>
            <Route path="/platform">
              <Platform></Platform>
            </Route>
            <Route path="/">
              <Home/>
            </Route>
          </Switch>
        </Suspense>
      </Router>
    );
  }
}

export default App;
