import axios from 'axios';

export const api = axios.create({
    baseURL: '/api'
})

export const initApis = ({setAuthenticated}) => {
    api.interceptors.response.use(
        (response)=>{
            setAuthenticated(true);
            return response;
        },
        (error) =>{
            let status = error.response.status;
            if (401 === status) {
                setAuthenticated(false);
            }
            return Promise.reject(error);
        }
    )
}
