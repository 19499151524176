import React, { useContext, createContext, useState, useEffect } from "react";

import { initApis, api } from "../../apis";
export const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState();
  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      try {
        initApis({ setAuthenticated });
        setAuthLoading(true);
        let response = await api.get("auth/verify");
        setUser(response.data.user);
        setAuthLoading(false);
      } catch (e) {
        setAuthLoading(false);
      }
    };

    init();
  }, []);
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authLoading,
        setAuthenticated: (b) => {
          setAuthenticated(b);
        },
        setUser: (user) => {
          setUser(user);
        },
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
