import React from 'react';
import styled from 'styled-components';
import {TailSpin} from 'svg-loaders-react';

const Container = styled.div`
    width: 100%;
    height: 100vh;
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
    color: green;
    & svg g path{
        stroke: green !important;
    }


`


export const Loader = () => {
    return(
        <Container>
            <TailSpin>            
            </TailSpin>
        </Container>
    )
}